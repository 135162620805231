/*.novel-value-fade-in{
	background-color: #1ABB9C;
    -webkit-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear !important;
}

.nove-value-fade-out{
	background-color: white;
	transition-property: background-color;
	transition-duration: 1s;
	transition-delay: 5s;
}


.example-enter {
  background-color: #000000;
}

.example-enter {
  background-color: #000000;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  width: 0px;
  transition: 700ms;
}*/

.novel-value-fade-in {
  background-color: #1ABB9C;
  transition-property: background-color;
  transition-duration: 1s;
}

.novel-value-fade-out {
  background-color: #FFFFFF;
  transition-property: background-color;
  transition-duration: 3s;
}

.dayelementswrapper {
  display: table;
  table-layout: fixed;

  width:100%;
  height:100px;
  background-color:Gray;
}
.dayelementswrapper div {
  display: table-cell;
  
}


@media (min-width: 768px){
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1  {
    width: 100%;
    *width: 100%;
  }
}

@media (min-width: 992px) {
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1 {
    width: 14.285714285714285714285714285714%;
    *width: 14.285714285714285714285714285714%;
  }
}

/**
 *  The following is not really needed in this case
 *  Only to demonstrate the usage of @media for large screens
 */    
@media (min-width: 1200px) {
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1 {
    width: 14.285714285714285714285714285714%;
    *width: 14.285714285714285714285714285714%;
  }
}
