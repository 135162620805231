.legend {

  position: relative;
}



.legend > * {
  display: inline-block;
}
.legend {
  font-size: 12px;
}
.legend-time {
  font-size: 12px;
}
.legend .series {
  display: flex;
}
.dygraph-axis-label.dygraph-axis-label-y {
  margin-top: -3px;
  margin-right: 2px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.dygraph-axis-label.dygraph-axis-label-y.dygraph-axis-label-y2 {
  margin-left: 4px;
  justify-content: flex-start;
}

#legendDivRelay, #legendDivRelayMaster {
  display: block;
  justify-content: flex-start;
  margin-right: -20px;
}
#legendDivRelay span {
  display: inline-block;
}
